import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { logout } from '@/backend/auth/auth';
import React, { useContext, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { GlobalContext } from '@/components/global-context-provider';
import { useQuery } from 'react-query';
import { BackendError, fetchUser, User } from '@/backend/api/api-service';

export function Navigation() {
  const router = useRouter();

  const userQuery = useQuery<User, BackendError>(
    ['user'],
    fetchUser,
  );

  const [menuOpen, setMenuOpen] = React.useState(false);

  const { disableNavigation } = useContext(GlobalContext);

  const menuOpenClass = '';// menuOpen ? 'ring-1 ring-stone-500 focus:outline-none' : '';

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (menuOpen && menuRef.current && !menuRef.current.contains(e.target as HTMLElement)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [menuOpen]);
  return (
    <div className="sm:sticky top-0 z-30">
      <div className={classNames(
        { hidden: !disableNavigation },
        'absolute w-full h-20 bg-stone-200 bg-opacity-75 z-50',
      )}
      />
      <div className="bg-white z-30">
        <div className="flex items-center justify-between px-2 h-20">
          <img className="w-16 py-2 " src="../logo.svg" alt="Logo" />
          <div className="flex">
            <button
              className={classNames(
                'font-title uppercase font-semibold tracking-wide',
                'text-xl text-stone-900 w-48 py-2 rounded-xl',
                { 'hover:bg-mk-light': !menuOpen },
              )}
              type="button"
              onClick={() => { if (!menuOpen) router.push('/wochen'); }}
            >
              Mein Marktkost
            </button>
            <div className="relative" ref={menuRef}>
              <div className={classNames('rounded-xl bg-white', menuOpenClass)}>

                <button
                  className={classNames(
                    'hidden xxs:block font-title uppercase font-semibold tracking-wide',
                    'text-xl text-stone-900 w-36 md:w-48 py-2 rounded-t-xl',
                    { 'rounded-b-xl hover:bg-mk-light': !menuOpen, 'bg-mk': menuOpen },
                  )}
                  type="button"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  Konto
                </button>

                <button
                  className={classNames(
                    'xxs:hidden font-title uppercase font-semibold tracking-wide text-xl',
                    'text-stone-900 w-20 py-2 rounded-t-xl',
                    { 'rounded-b-xl hover:bg-mk-light': !menuOpen, 'bg-mk': menuOpen },
                  )}
                  type="button"
                  onClick={() => setMenuOpen(!menuOpen)}
                >
                  <FontAwesomeIcon icon={faUser} />
                </button>

                <div className={classNames(
                  'absolute right-0 z-30 bg-white w-48 shadow-sm flex flex-col gap-3',
                  'py-2 px-4 rounded-b-xl',
                  { hidden: !menuOpen },
                )}
                >
                  { userQuery.data && (
                  <div className="text-stone-700 border-b border-mk-900 pb-2 text-right">
                    {userQuery.data?.firstName}
                    {' '}
                    {userQuery.data?.lastName}
                  </div>
                  ) }
                  <button
                    type="button"
                    className="text-stone-700 block text-xl text-left hover:text-stone-900"
                    onClick={() => { setMenuOpen(false); router.push('/bestellungen'); }}
                  >
                    Bestellungen
                  </button>
                  <button
                    type="button"
                    className="text-stone-700 block text-xl text-left hover:text-stone-900"
                    onClick={() => { setMenuOpen(false); router.push('/einstellungen'); }}
                  >
                    Einstellungen
                  </button>
                  <button
                    type="button"
                    className="text-stone-700 block text-xl text-left hover:text-stone-900"
                    onClick={() => { setMenuOpen(false); logout(); router.push('/login'); }}
                  >
                    Abmelden
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
