import React, {
  Dispatch, PropsWithChildren, SetStateAction, useMemo, useState,
} from 'react';

interface IGlobalContext {
  disableNavigation: boolean,
  setDisableNavigation: Dispatch<SetStateAction<boolean>>,
}

export const GlobalContext = React.createContext<IGlobalContext>({
  disableNavigation: false,
  setDisableNavigation: () => {
  },
});

export function GlobalContextProvider({ children }: PropsWithChildren) {
  const [disableNavigation, setDisableNavigation] = useState(false);

  const globalContextValue = useMemo(() => ({
    disableNavigation, setDisableNavigation,
  }), [disableNavigation]);

  return (
    <GlobalContext.Provider value={globalContextValue}>
      {children}
    </GlobalContext.Provider>
  );
}
