import React from 'react';
import { GlobalContextProvider } from '@/components/global-context-provider';
import { Navigation } from '@/components/navigation';
import { Footer } from '@/components/footer';

export type LayoutFunction = (page: React.ReactElement) => React.ReactElement;

export function UserAppLayout(page: React.ReactElement) {
  return (
    <GlobalContextProvider>
      <div className="min-h-screen flex flex-col justify-between">
        <div>
          <Navigation />
          <main className="lg:container mx-auto px-2">{page}</main>
        </div>
        <Footer />
      </div>
    </GlobalContextProvider>
  );
}
